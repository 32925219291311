import algoliasearch from 'algoliasearch'
import TypesenseInstantsearchAdapter from 'typesense-instantsearch-adapter'
import { IS_TYPESENSE_ENABLED } from 'config'
import { getTypesenseObject, getTypesenseObject2 } from 'api/typesense.api'
import qs from 'qs'
import { NextRouter } from 'next/router'
import { SearchState } from 'react-instantsearch-core'

const TYPESENSE_PROTOCOL = 'https'
const TYPESENSE_PORT = 443

type SearchParams = {
  ts_queryBy?: string
  ts_sortBy?: string
}
export const isTypesenseEnabled = () =>
  IS_TYPESENSE_ENABLED === 'TRUE' && !!process.env.TYPESENSE_API_KEY && !!process.env.TYPESENSE_HOST

export const getSearchClient = ({ ts_queryBy, ts_sortBy }: SearchParams = {}) => {
  if (isTypesenseEnabled()) {
    const typesenseInstantSearchAdapter = new TypesenseInstantsearchAdapter({
      server: {
        apiKey: process.env.TYPESENSE_API_KEY || '',
        nodes: [
          {
            host: process.env.TYPESENSE_HOST || '',
            port: TYPESENSE_PORT,
            protocol: TYPESENSE_PROTOCOL,
          },
        ],
      },
      additionalSearchParameters: {
        query_by: ts_queryBy || 'name',
        sort_by: ts_sortBy,
      },
    })

    return typesenseInstantSearchAdapter.searchClient
  } else {
    return algoliasearch(process.env.algoliaAppId || '', process.env.algoliaAPIKey || '')
  }
}

export const getSearchClient2 = ({ ts_queryBy, ts_sortBy }: SearchParams = {}) => {
  if (isTypesenseEnabled()) {
    const typesenseInstantSearchAdapter = new TypesenseInstantsearchAdapter({
      server: {
        apiKey: process.env.TYPESENSE_API_KEY2 || '',
        nodes: [
          {
            host: process.env.TYPESENSE_HOST2 || '',
            port: TYPESENSE_PORT,
            protocol: TYPESENSE_PROTOCOL,
          },
        ],
      },
      additionalSearchParameters: {
        query_by: ts_queryBy || 'name',
        sort_by: ts_sortBy,
      },
    })

    return typesenseInstantSearchAdapter.searchClient
  } else {
    return algoliasearch(process.env.algoliaAppId || '', process.env.algoliaAPIKey || '')
  }
}

export const parseLeagueFilters = (leagueSortingCard: string[]) => {
  if (isTypesenseEnabled()) {
    return leagueSortingCard?.length > 0 ? leagueSortingCard : []
  }
  const facetFilterValue = []
  for (let index = 0; index < leagueSortingCard.length; index++) {
    const element = leagueSortingCard[index]
    const facetFilterItem = `associated_leagues: ${element}`
    facetFilterValue.push(facetFilterItem)
  }
  return facetFilterValue
}

export const getSearchObjectsById = async (searchObjectArray: { index: string; id: string }[]) => {
  if (isTypesenseEnabled()) {
    return await Promise.all([
      getTypesenseObject(searchObjectArray[0].index, searchObjectArray[0].id),
      getTypesenseObject(searchObjectArray[1].index, searchObjectArray[1].id),
    ])
  } else {
    const searchIndex1 = getSearchClient().initIndex(searchObjectArray[0].index)
    const searchIndex2 = getSearchClient().initIndex(searchObjectArray[1].index)

    return await Promise.all([
      searchIndex1.getObject(searchObjectArray[0].id as string),
      searchIndex2.getObject(searchObjectArray[1].id as string),
    ])
  }
}

export const getSearchObjectById = async (index: string, id: string) => {
  if (isTypesenseEnabled()) {
    return await getTypesenseObject(index, id)
  } else {
    const searchIndex = getSearchClient().initIndex(index)
    const doc = await searchIndex.getObject(id)
    return doc
  }
}

export const getSearchObjectById2 = async (index: string, id: string) => {
  if (isTypesenseEnabled()) {
    return await getTypesenseObject2(index, id)
  } else {
    const searchIndex = getSearchClient2().initIndex(index)
    const doc = await searchIndex.getObject(id)
    return doc
  }
}

/**
 *  Used to pass InstantSearch createURL props to the SearchList component
 * Example:
 * input: {q: "", page: "2", sort: "price_asc"}
 * output: ?q=&page=2&sort=price_asc
 *
 * @param state (SearchState)
 * @returns String
 */
export const createURL = (state: object) => {
  return `${qs.stringify(state)}`
}

export const searchStateToURL = (location: NextRouter, searchState: SearchState) => {
  return searchState ? `${location.pathname}${`?${createURL(searchState)}`}` : ''
}

/**
 * Used in serverside rendering to get the search results with the initial search state
 *
 * Example:
 * input: /marketplace?q=&page=2&sort=price_asc
 * output: {q: "", page: "2", sort: "price_asc"}
 *
 * @param path (string)
 * @returns Object
 */

export const pathToSearchState = (path: string) =>
  path.includes('?') ? qs.parse(path.substring(path.indexOf('?') + 1)) : {}
